import { render, staticRenderFns } from "./micro_habitation.vue?vue&type=template&id=6b77d11a&"
import script from "./micro_habitation.vue?vue&type=script&lang=js&"
export * from "./micro_habitation.vue?vue&type=script&lang=js&"
import style0 from "./micro_habitation.vue?vue&type=style&index=0&id=6b77d11a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports