<template>
  <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">
    <div class="page-header">
      <h1>Liste des <span style="color:#ffa409">clients</span> et <span style="color:#ffa409">prospects</span>
        <theme-picker style="float:right"></theme-picker>
      </h1>
    </div>

    <div class="toolbar-box flex align-center" v-if="!isMobile">
      <div class="box grow">
        <el-input
            placeholder="Rechercher client ou prospect..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable
        >
        </el-input>
      </div>
      <div class="box grow">
        <!--        A ne pas supprimer-->
      </div>
    </div>

    <div v-if="isMobile" style="margin-bottom:15px">
      <el-row :gutter="15">
        <el-col :span="24" style="margin-top:15px">
          <el-input
            placeholder="Rechercher client ou prospect..."
            prefix-icon="el-icon-search"
            v-model="search"
            clearable>
        </el-input>
        </el-col>
      </el-row>
    </div>

    <resize-observer @notify="handleResize" />
    <div class="mb-5" v-if="this.$store.state.user.role == 'Admin' || this.$store.state.user.role == 'SuperAdmin'">
            <el-button type="primary" round v-loading.fullscreen.lock="modalgenerationloading" @click="exportToExcel()">Exporter en Excel</el-button>
        </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="!isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Nom" min-width="150" fixed prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
        <el-table-column label="Adresse mail" min-width="200" prop="email"></el-table-column>
        <el-table-column label="Téléphone" min-width="200" prop="telephone"></el-table-column>
        <el-table-column label="Nombre de devis" min-width="200" prop="devisCount"></el-table-column>
        <el-table-column label="Nombre de contrat" min-width="200" prop="contratCount"></el-table-column>
        <el-table-column label="Type Client" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.contratCount != 0">Client</span>
            <span v-if="scope.row.contratCount == 0">Prospect</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="action" min-width="130">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailclient(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" :disabled="scope.row.contratCount == 0" v-if="$store.state.user.role=='Admin' || $store.state.user.role=='Agent'" @click="contratclientaction(scope.row.id)" type="primary" icon="el-icon-document-checked" circle title="liste contrat"></el-button>
            <el-button size="mini" @click="devisclientaction(scope.row.id)" type="primary" icon="el-icon-document" circle title="liste devis"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>

    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready" v-if="isMobile">
      <el-table
          :data="listInPage"
          style="width: 100%"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Nom" min-width="150" prop="nom"></el-table-column>
        <el-table-column label="Prénom" min-width="150" prop="prenom"></el-table-column>
        <el-table-column label="Adresse mail" min-width="200" prop="email"></el-table-column>
        <el-table-column label="Téléphone" min-width="200" prop="telephone"></el-table-column>
        <el-table-column label="Nombre de devis" min-width="200" prop="devisCount"></el-table-column>
        <el-table-column label="Nombre de contrat" min-width="200" prop="contratCount"></el-table-column>
        <el-table-column label="Type Client" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.contratCount != 0">Client</span>
            <span v-if="scope.row.contratCount == 0">Prospect</span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" prop="action" min-width="130">
          <template slot-scope="scope">
            <el-button size="mini" @click="detailclient(scope.row)" type="primary" icon="el-icon-view" circle title="Voir détails"></el-button>
            <el-button size="mini" :disabled="scope.row.contratCount == 0" v-if="$store.state.user.role=='Admin' || $store.state.user.role=='Agent'" @click="contratclientaction(scope.row.id)" type="primary" icon="el-icon-document-checked" circle title="liste contrat"></el-button>
            <el-button size="mini" @click="devisclientaction(scope.row.id)" type="primary" icon="el-icon-document" circle title="liste devis"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="total"
      ></el-pagination>
    </div>
    
    <el-dialog width="70%!important" title="Détails client" :visible.sync="showclient" v-if="showclient==true && !isMobile">
      <el-row :gutter="15">
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsClient.nom.toUpperCase()}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsClient.prenom.toUpperCase()}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsClient.email}}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsClient.telephone}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsClient.photo" width="100" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="90%!important" title="Détails client" :visible.sync="showclient" v-if="showclient==true && isMobile">
      <el-row :gutter="15">
        <el-col :span="24">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div  >
                <ul style="list-style:none">
                  <li>Nom : <span class="to-right assuraf-orange">{{detailsClient.nom.toUpperCase()}}</span></li>
                  <li>Prénom : <span class="to-right assuraf-orange">{{detailsClient.prenom.toUpperCase()}}</span></li>
                  <li>Email : <span class="to-right assuraf-orange">{{detailsClient.email}}</span></li>
                  <li>Téléphone Portable : <span class="to-right assuraf-orange">{{detailsClient.telephone}}</span></li>
                </ul>
              </div>
            </el-card>
          </div>
        </el-col>
        <el-col :span="24" style="margin-top:15px">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div style="text-align: center!important;">
                <img class="justify-center" :src="detailsClient.photo" width="200" alt="photo de profil">
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog width="70%!important" title="Liste des devis du client" :visible.sync="modalDevis" v-if="modalDevis==true && !isMobile">
      <!-- listInPageDevis -->
      <el-table
          :data="listdevis"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Assureur" min-width="150"  prop="partenaireid.societe" :fixed="!isMobile"></el-table-column>
        <el-table-column label="Numéro de police" min-width="200" prop="numeroDevis"></el-table-column>
        <el-table-column label="Courtier" min-width="200" prop="courtier.societe"></el-table-column>
        <el-table-column label="Branche" min-width="200" prop="brancheid.branche"></el-table-column>
          <el-table-column label="Date Souscription" min-width="200" prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Effet" min-width="200" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echéance" min-width="200" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>
        <el-table-column label="Taxe" min-width="200" prop="taxe">
          <template slot-scope="scope">
            {{scope.row.taxe.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" fixed="right" prop="prime_nette" v-if="!isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
         <el-table-column label="Prime nette" min-width="200" prop="prime_nette" v-if="isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="listdevis.length"
      ></el-pagination>
    </el-dialog>

    <el-dialog width="90%!important" title="Liste des devis du client" :visible.sync="modalDevis" v-if="modalDevis==true && isMobile">
      <el-table
          :data="listInPageDevis"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Assureur" min-width="150"  prop="partenaireid.societe" :fixed="!isMobile"></el-table-column>
        <el-table-column label="Numéro de police" min-width="200" prop="numeroDevis"></el-table-column>
        <el-table-column label="Courtier" min-width="200" prop="courtier.societe"></el-table-column>
        <el-table-column label="Branche" min-width="200" prop="brancheid.branche"></el-table-column>
          <el-table-column label="Date Souscription" min-width="200" prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Effet" min-width="200" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echéance" min-width="200" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>
        <el-table-column label="Taxe" min-width="200" prop="taxe">
          <template slot-scope="scope">
            {{scope.row.taxe.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" fixed="right" prop="prime_nette" v-if="!isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
         <el-table-column label="Prime nette" min-width="200" prop="prime_nette" v-if="isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="totalDevis"
      ></el-pagination>
    </el-dialog>

    <el-dialog width="70%!important" title="Liste des contrats du client" :visible.sync="modalContrat" v-if="modalContrat==true && !isMobile">
      <el-table
          :data="listcontrat"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Assureur" min-width="150"  prop="partenaireid.societe" :fixed="!isMobile"></el-table-column>
        <el-table-column label="Numéro de police" min-width="200" prop="numero_police"></el-table-column>
        <el-table-column label="Courtier" min-width="200" prop="courtier.societe"></el-table-column>
        <el-table-column label="Branche" min-width="200" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Date Souscription" min-width="200" prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Effet" min-width="200" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echéance" min-width="200" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>
        <el-table-column label="Taxe" min-width="200" prop="taxe">
          <template slot-scope="scope">
            {{scope.row.taxe.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" fixed="right" prop="prime_nette" v-if="!isMobile">
          <template slot-scope="scope">
             <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" prop="prime_nette" v-if="isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="totalContrat"
      ></el-pagination>
    </el-dialog>

    <el-dialog width="90%!important" title="Liste des contrats du client" :visible.sync="modalContrat" v-if="modalContrat==true && isMobile">
      <el-table
          :data="listcontrat"
          style="width: 100%"
          :height="height"
          v-if="ready"
          @selection-change="handleSelectionChange"
      >
        <el-table-column label="Assureur" min-width="150"  prop="partenaireid.societe" :fixed="!isMobile"></el-table-column>
        <el-table-column label="Numéro de police" min-width="200" prop="numero_police"></el-table-column>
        <el-table-column label="Courtier" min-width="200" prop="courtier.societe"></el-table-column>
        <el-table-column label="Branche" min-width="200" prop="brancheid.branche"></el-table-column>
        <el-table-column label="Date Souscription" min-width="200" prop="dateSouscription">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateSouscription) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Effet" min-width="200" prop="dateEffet">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEffet) }}
          </template>
        </el-table-column>
        <el-table-column label="Date Echéance" min-width="200" prop="dateEcheance">
          <template slot-scope="scope">
            {{ DateFormater(scope.row.dateEcheance) }}
          </template>
        </el-table-column>
        <el-table-column label="Taxe" min-width="200" prop="taxe">
          <template slot-scope="scope">
            {{scope.row.taxe.toLocaleString() }} CFA
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" fixed="right" prop="prime_nette" v-if="!isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
        <el-table-column label="Prime nette" min-width="200" prop="prime_nette" v-if="isMobile">
          <template slot-scope="scope">
            <h3>{{scope.row.prime_nette.toLocaleString() }} CFA</h3>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-if="ready"
          :small="pagination.small"
          :current-page.sync="pagination.page"
          :page-sizes="pagination.sizes"
          :page-size.sync="pagination.size"
          :layout="pagination.layout"
          :total="totalContrat"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>

import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
//fin importation assuraf

export default {
  name: "StyledTablePage",
  data() {
    return {
      listcontrat:[],
      listcontratCount:null,
      lineChartData: {
        labels: ["January", "February", "March", "April", "May", "June", "July"],

        datasets: [{
          label: "Contrats",
          backgroundColor: "rgba(255, 99, 132, 0.1)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 0.7,
          data: [65, 59, 80, 81, 56, 55, 40]
        },
          {
            label: "Devis",
            backgroundColor: "rgba(151,187,205,0.2)",
            borderColor: "rgba(151,187,205,1)",
            borderWidth: 0.8,
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      lineChartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }]
        }
      },

      listdevisc: [],
      devisclient: false,
      paramclient: null,
      nbDevis: 0,
      table_first: null,


      currentPageDetails: 1,
      totalRowsDetails: null,
      perPageDetails: 10,

      currentPageDetailsContrat: 1,
      totalRowsDetailsContrat: null,
      perPageDetailsContrat: 10,

      contratclient: false,
      NewTabClient: [],
      detailsClient: [],
      modalDevis: false,
      modalContrat: false,
      modalstat: false,

      listeclient: [],
      userid: null,
      showclient: false,
      fields: [
        {
          key: 'nom',
          label: 'Nom'
        },
        {
          key: 'prenom',
          label: 'Prenom'
        },
        {
          key: 'email',
          label: 'email'
        },
        {
          key: 'telephone',
          label: 'Telephone'
        },
        {
          key: 'devisCount',
          label: 'Nombre de Devis',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'contratCount',
          label: 'Nombre Contrats',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'action',
          label: 'Action'
        },
      ],
      fieldsdevis: [
        {
          key: 'partenaireid.societe',
          label: 'Assureur',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'numeroDevis',
          label: "Numéro devis"
        },
        {
          key: 'courtier.societe',
          label: 'Courtier',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche',
          sortable: true,
          sortDirection: 'desc'
        },
         {
          key: 'dateSouscription',
          label: 'Date de souscription',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'dateEffet',
          label: 'date Effet',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'dateEcheance',
          label: 'date Echeance',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'prime_nette',
          label: 'Prime nette',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'taxe',
          label: 'Taxe',
          sortable: true,
          sortDirection: 'desc'
        },

      ],
      fieldsContrat: [
        {
          key: 'partenaireid.societe',
          label: 'Assureur',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'numero_police',
          label: 'Numéro de police'
        },
        {
          key: 'courtier.societe',
          label: 'Courtier',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'brancheid.branche',
          label: 'Branche',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'dateSouscription',
          label: 'Date de souscription',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'dateEffet',
          label: 'Date Effet',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'dateEcheance',
          label: 'Date Echeance',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'prime_nette',
          label: 'Prime nette',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'taxe',
          label: 'taxe',
          sortable: true,
          sortDirection: 'desc'
        },

      ],
      modalgenerationloading : false,
      optionsdate: {
        format: 'YYYY/MM/DD',
        useCurrent: false,
      },
      ldevis: [],
      du: null,
      au: null,
      listdevis: [],
      modalgeneration: false,
      detailsDevis: [],
      showdevis: false,
      pageOptions: [5, 10, 15],

      fieldsFiltres: [{
        key: 'dateSouscription',
        label: 'Date creation'
      },
        {
          key: 'brancheid.branche',
          label: 'Branche'
        },
        {
          key: 'numeroDevis',
          label: 'Num devis'
        },
        {
          key: 'partenaireid.societe',
          label: 'Assureur'
        },
        {
          key: 'courtier.societe',
          label: 'Courtier'
        },
        {
          key: 'dateEffet',
          label: 'Effet'
        },
        {
          key: 'dateEcheance',
          label: 'Echeance'
        },
        {
          key: 'prime_ttc',
          label: ' Prime TTC'
        },
        {
          key: 'creator',
          label: 'Createur'
        },
        {
          key: 'action',
          label: 'Action'
        },

      ],
      ToastTabSucess: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "success",
        className: "custom-success-class",
      }, // Customized options for success toast
      ToastTabError: {
        theme: "toasted-primary",
        position: "top-center",
        duration: 10000,
        fitToScreen: true,
        type: "error",
      }, // Customized options for error toast
      modalInfo: {
        title: '',
        content: ''
      },
      MenuHeader:[
        {key: "theme",label: "Libéllé"},
        {key: "collapse",label: "Collapse"},
        {key: "sousmenu",label: "Sous menu"},
        {key: "action", label:"action"}
      ],
      userflette:[],
      fletteRows:null,
      carFields:[
        {key:'marque',label:'Marque', sortable: true, sortDirection: 'desc'},
        {key:'model',label:'Model', sortable: true, sortDirection: 'desc'},
        {key:'immatriculation',label:'Immatriculation', sortable: true, sortDirection: 'desc'},
        {key:'carburation',label:'Carburation', sortable: true, sortDirection: 'desc'},
        {key:'added_date',label:"Date d'ajout"},
        {key:'action',label:'Action'},
      ],
      editUser : false,
      Selectedbranche:null,
      selectedAssureur:[],
      configUserDevisAssureur:null,
      assureurs:[],
      branchelist:null,
      modalSociete: false,
      configHead:[
        { key:"branche", label:"branche"},
        { key:"assureurs", label:"assureur"},
        { key:"action", label:"Action"}
      ],
      selectedUser:null,
      listeAssureurs: null,
      usermenu:[],
      collapse:null,
      menu_name:null,
      showConfigMenu:false,
      submenu:null,
      userSubmenu:[],
      UserFinalMenu:[],

      username: null,
      data: {},
      html: '',
      Mycounter: 0,
      dismissSecs: 3,
      totalRows: null,
      perPage: 8,
      UpdateOk: false,
      deleteConfirmation: false,
      modalDelete: false,
      dismissCountDown: 0,
      detailsUser: [],
      listuser: null,
      filter: null,
      showuser: false,

      voidField: false,
      isMobile: false,
      ready: false,
      width: 0,
      height: "auto",
      loading: false,
      search: "",
      pagination: {
        page: 1,
        size: 100,
        sizes: [10, 15, 20, 30, 50, 100],
        layout: "total, ->, prev, pager, next, jumper, sizes",
        small: false
      },
      list: this.NewTabClient, //users,
      editMode: false,
      itemsChecked: [],
      dialogUserVisible: false,
      currentId: 0,
      moment
    }
  },
  computed: {
    listFiltered() {
      return this.NewTabClient.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSortered() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFiltered.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPage() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      //return this.listSortered.slice(from, to)
      return this.listFiltered.slice(from, to)
    },
    total() {
      return this.listFiltered.length
    },

    listFilteredDevis() {
      return this.table_first.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSorteredDevis() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredDevis.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageDevis() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listSorteredDevis.slice(from, to)
      return this.listFilteredDevis.slice(from, to)
    },
    totalDevis() {
      return this.listFilteredDevis.length
    },

    listFilteredContrat() {
      return this.table_first.filter(obj => {
        let ctrl = false
        for (let k in obj) {
          if (
              obj[k] &&
              obj[k]
                  .toString()
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) !== -1
          )
            ctrl = true
        }
        return ctrl
      })
    },
    listSorteredContrat() {
      let prop = this.sortingProp
      let order = this.sortingOrder
      return [].concat(
          this.listFilteredContrat.sort((item1, item2) => {
            let val1 = ""
            let val2 = ""

            val1 = item1[prop]
            val2 = item2[prop]
            if (order === "descending") {
              return val2 < val1 ? -1 : 1
            }
            return val1 < val2 ? -1 : 1
          })
      )
    },
    listInPageContrat() {
      let from = (this.currentPage - 1) * this.itemPerPage
      let to = from + this.itemPerPage * 1
      return this.listSorteredContrat.slice(from, to)
      return this.listFilteredContrat.slice(from, to)
    },
    totalContrat() {
      return this.listFilteredContrat.length
    },

    currentPage: {
      get() {
        return this.pagination.page
      },
      set(val) {
        this.pagination.page = val
      }
    },
    itemPerPage() {
      return this.pagination.size
    },
    selectedItems() {
      return this.itemsChecked.length || 0
    }
  },
  watch: {
    itemPerPage(val) {
      this.ready = false
      this.currentPage = 1

      setTimeout(() => {
        this.ready = true
      }, 500)
    },
    search(val) {
      this.currentPage = 1
    }
  },
  methods: {
    exportToExcel(){
          //API de genereation donne en excel
        },
    devisclientaction(param) {
      Api().get('/devis/mydevis/' + param)
          .then(resultat => {
            this.listdevis = []
            this.modalDevis = true
            this.table_first = resultat.data.devis
            if (this.$store.state.user.role == 'Agent' && this.$store.state.user.societe) {
              for (let index = 0; index < this.table_first.length; index++) {
                if (this.table_first[index].courtier!=null && typeof this.table_first[index].courtier !="undefined") {
                  if (this.table_first[index].courtier._id === this.$store.state.user.societe) {
                    this.listdevis.push(this.table_first[index])
                  }
                }
              }
            } else if (this.$store.state.user.role == 'Admin') {
              this.listdevis = this.table_first
            } else if (this.$store.state.user.role == 'assureur') {
              for (let index = 0; index < this.table_first.length; index++) {
                if (this.table_first[index].partenaireid._id === this.$store.state.user.societe) {
                  this.listdevis.push(this.table_first[index])

                }
              }
            }

          });
      this.devisclient = true
    },

    contratclientaction(param) {

      Api().get('/contrat/user/' + param)
          .then(async resultat => {
            this.listcontrat = []
            this.listcontratCount =  resultat.data.contrats.length
            this.modalContrat = true
            this.table_first = resultat.data.contrats
            if (this.$store.state.user.role == 'Agent') {
              for (let index = 0; index < this.table_first.length; index++){
                if(typeof this.table_first[index].courtierid !='undefined'){
                  if (this.table_first[index].courtierid._id === this.$store.state.user.societe){
                    this.listcontrat.push(this.table_first[index])
                  }
                }
              }
            } else if (this.$store.state.user.role == 'Admin') {
              this.listcontrat = this.table_first
            }

          });
      this.contratclient = true
    },

    detailclient(item) {
      this.detailsClient = item
      console.log(this.detailsClient)
      this.showclient = true
    },
    statclient() {
      this.modalstat = true
    },

    // Filtrage de la liste des utilisateur ayant un contrat (selon profile de l'admin connecte)
    GetListeClient: function () {
      if (store.state.user.role == 'Agent') {
        Api().get('/user/client/all/courtier/' + store.state.user.societe)
            .then(resultat => {
              this.listeclient = resultat.data
              for (let index = 0; index < this.listeclient.length; index++) {
                const client = {
                  id: this.listeclient[index].userinfo[0]._id,
                  nom: this.listeclient[index].userinfo[0].nom,
                  prenom: this.listeclient[index].userinfo[0].prenom,
                  email: this.listeclient[index].userinfo[0].email,
                  telephone: this.listeclient[index].userinfo[0].telephone_port,
                  adresse: this.listeclient[index].userinfo[0].adresse,
                  photo: this.listeclient[index].userinfo[0].photo,
                  devisCount: this.listeclient[index].totalCount,
                  contratCount: this.listeclient[index].userscontrats.length
                }
                this.NewTabClient.push(client)
              }

            });
      } else if (store.state.user.role == 'assureur' || store.state.user.role == 'agent général') {

        Api().get('/user/client/assureur/' + store.state.user.societe)
            .then(resultat => {
              this.listeclient = resultat.data

              for (let index = 0; index < this.listeclient.length; index++) {

                const client = {
                  id: this.listeclient[index].id,
                  nom: this.listeclient[index].nom,
                  prenom: this.listeclient[index].prenom,
                  email: this.listeclient[index].email,
                  telephone: this.listeclient[index].telephone,
                  adresse: this.listeclient[index].adresse,
                  photo: this.listeclient[index].photo,
                  contratCount: this.listeclient[index].contratCount,
                  devisCount: this.listeclient[index].devisCount.length
                }

                this.NewTabClient.push(client)
              }

            });
      } else if (store.state.user.role == 'SuperAdmin' || this.$store.state.user.role == 'Admin' ) {
        Api().get('/user/clientorprospect/')
            .then(resultat => {
              this.listeclient = resultat.data
              for (let index = 0; index < this.listeclient.length; index++) 
              {
                const client = {
                  id: this.listeclient[index].userinfo[0]._id||null,
                  nom: this.listeclient[index].userinfo[0].nom,
                  prenom: this.listeclient[index].userinfo[0].prenom,
                  email: this.listeclient[index].userinfo[0].email,
                  telephone: this.listeclient[index].userinfo[0].telephone_port,
                  adresse: this.listeclient[index].userinfo[0].adresse,
                  photo: this.listeclient[index].userinfo[0].photo,
                  devisCount: this.listeclient[index].totalCount,
                  contratCount: this.listeclient[index].userscontrats.length
                }
                this.NewTabClient.push(client)
              }

            })

      }
    },

    info(item) {
      alert(JSON.stringify(item))
    },


    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },

    rowClass(item, type) {
      if (!item) {
        return 'success-row';
        alert(item)
      } else if (item.vueAdmin ===  false) {
        return 'success-row';
      }
      return '';

      /*if (!item) return
      if (item.vueAdmin === false) return 'success-row'*/
    },


    FiltreDate() {
      if (this.du && this.au) {
        switch (store.state.user.role) {
          case 'Admin':
            Api().post('devis/filtre/periode', {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          case 'Agent':
            Api().post('devis/filtre/courtier/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          case 'assureur':
            Api().post('devis/filtre/assureur/periode/' + store.state.user.societe, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;
          case 'agent général':
            Api().post('devis/filtre/assureur/periode/' + store.state.user.assureur, {
              du: this.du,
              au: this.au
            })
                .then(resultat => {
                  this.listdevis = resultat.data.devis
                });
            break;

          default:
            break;
        }
      }

    },


    toggleDetails(item){
      this.detailsUser = item
      this.editUser = true
    },


    ready: function () {
      var self = this;

      setInterval(function () {
        self.$data.ticker = Date.now();
      }, 1000);
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    DateFormater: function (TheDate) {
      if (TheDate) {
        return dayjs(TheDate).format('DD-MM-YYYY')
      }
    },


    calcDims() {
      const tableWrapper = document.getElementById("table-wrapper")
      if (tableWrapper) this.width = tableWrapper.clientWidth

      if (!this.isMobile && tableWrapper) {
        this.height = tableWrapper.clientHeight - 44
      }

      if (this.width < 480) {
        this.pagination.small = true
        this.pagination.layout = "prev, pager, next"
      } else if (this.width >= 480 && this.width < 700) {
        this.pagination.small = false
        this.pagination.layout = "prev, pager, next, ->, sizes"
      } else {
        this.pagination.small = false
        this.pagination.layout = "total, ->, prev, pager, next, jumper, sizes"
      }

      this.ready = true
    },

    handleResize: _.throttle(function(e) {
      this.ready = false
      this.width = 0
      setTimeout(this.calcDims, 1000)
    }, 500),
    handleSelectionChange(val) {
      this.itemsChecked = val
    },
    init() {
      if (window.innerWidth <= 768) this.isMobile = true
    }
  },
  filters: {
    selected: function(value, sel) {
      if (!value) return ""
      if (!sel) return value

      value = value.toString()
      sel = sel.toString()

      const startIndex = value.toLowerCase().indexOf(sel.toLowerCase())
      if (startIndex !== -1) {
        const endLength = sel.length
        const matchingString = value.substr(startIndex, endLength)
        return value.replace(matchingString, `<span class="sel">${matchingString}</span>`)
      }

      return value
    }
  },
  created() {
    this.init()
  },
  mounted() {
    if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {
      this.GetListeClient()
    } else {
      this.$router.go(this.$router.push('/login'))
    }

    //ie fix
    if (!window.Number.parseInt) window.Number.parseInt = parseInt

    this.calcDims()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";

.page-table {
  &.overflow {
    overflow: auto;
  }

  .toolbar-box {
    &.hidden {
      visibility: hidden;
    }
  }

  .table-box {
    overflow: hidden;

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
@import "../../../assets/scss/_variables";

.page-table {
  .toolbar-box {
    margin-bottom: 10px;

    .card-shadow--medium {
      &.el-input,
      &.el-select {
        border-radius: 4px;

        input {
          border-color: transparent;
          background-color: lighten($background-color, 2%);
          color: $text-color;

          &:hover {
            border-color: #c0c4cc;
          }
          &:focus {
            border-color: $text-color-accent;
          }
        }
      }

      &.el-button {
        border-color: transparent;
      }
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  .sel-string {
    .sel {
      background: transparentize($text-color, 0.8);
      border-radius: 5px;
      //text-transform: uppercase;
    }
  }
}

@media (max-width: 768px) {
  .page-table {
    .toolbar-box {
      display: block;
      overflow: hidden;
      font-size: 80%;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        //background: rgba(0, 0, 0, 0.04);
        margin-bottom: 16px;
      }
    }
  }
}
</style>
