<template>
    <vue-scroll class="page-devis-voyage">
        <div class="page-header">
            <h1>Création de devis <span style="color:#ffa409">Micro Auto</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <div v-if="!isMobile" class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <el-row :gutter="15" type="flex" class="row-bg">
                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <label>Choix Pack</label>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="simulate.pack" size="medium" :label="Micro_auto.pack1">Pack
                                        1</el-radio>
                                    <el-radio v-model="simulate.pack" :label="Micro_auto.pack2">Pack 2</el-radio>
                                </div>

                            </el-col>
                        </div>
                    </el-col>
                    <el-col :span="6" v-if="simulate.pack == Micro_auto.pack2 || simulate.pack == Micro_auto.pack1">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <label>Période (jours/mois)</label>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-radio v-model="simulate.jours_mois" size="medium"
                                        :label="Micro_auto.periodeType.day" @change="clearDuree" >En jours</el-radio>
                                    <el-radio v-model="simulate.jours_mois"  @change="clearDuree" :label="Micro_auto.periodeType.month">En
                                        mois</el-radio>
                                </div>
                            </el-col>
                        </div>
                    </el-col>

                    <!-- <el-col :span="8" v-if="simulate.pack == Micro_auto.pack1">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <el-col>
                                <label>Durée en jours</label>
                            </el-col>
                            <el-col>
                                <el-select v-model="simulate.duree" placeholder="Selectionner">
                                    <el-option v-for="items in Micro_auto.durationInDay" :key="items"
                                        :label="items + ' jours'" :value="items">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </div>
                    </el-col> -->
                    <el-col :span="6" v-if="simulate.pack == Micro_auto.pack2 || simulate.pack == Micro_auto.pack1">
                        <div v-if="simulate.jours_mois == 'jours'" class="grid-content bg-purple"
                            style="margin-left: 20px;">
                            <label>Durée en jours</label>
                            <el-col>
                                <el-select style="width: 100%!important;" v-model="simulate.duree"
                                    placeholder="Selectionner">
                                    <el-option v-for="items in Micro_auto.durationInDay" :key="items"
                                        :label="items + ' jours'" :value="items">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </div>
                        <div v-if="simulate.jours_mois == 'mois'" class="grid-content bg-purple"
                            style=";margin-left: 20px;">
                            <label>Durée en mois</label>
                            <el-select style="width: 100%!important;" v-model="simulate.duree" placeholder="Selectionner"  @change="dateDiff">
                                <el-option v-for="items in Micro_auto.durationInMonth" :key="items" :label="items + ' mois'"
                                    :value="items">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="24" style="margin-top:20px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <label>Date d'effet</label>
                            <el-date-picker @change="dateDiff" style="width: 100%!important;" v-model="simulate.dateEffet"
                                type="date" format="yyyy/MM/dd" placeholder="Choississez une date"
                                :picker-options="dateAfterToday">
                            </el-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <label>Date d"échéance</label>
                            <el-input placeholder="Date d'échéance" v-model="simulate.dateEcheance" readonly>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-col :span="24" style="margin-top:30px">

                    <el-descriptions style="font-size:12px" v-if="simulate.pack == Micro_auto.pack1">
                        <hr />
                        <el-descriptions-item label="Garanties inclus ">
                            <span style="color:#ffa409"> {{ simulate.pack.garantie }} </span>
                        </el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions style="font-size:12px" v-if="simulate.pack == Micro_auto.pack2">
                        <el-descriptions-item label="Garanties inclus">
                            <span style="color:#ffa409"> {{ simulate.pack.garantie }} </span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </div>

            <div style="margin-top:15px">
                <el-button  @click="simulateAuto()" type="primary" round>Simuler</el-button>
            </div>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20"
            v-if="simulate.prime_nette != null">
            <el-row>
                <el-col :span="12">
                    <div class="text-center">
                        <p>Pack : <span style="color:#ffa409" class="bolder-text">{{ simulate.pack.namePack }}</span> </p>
                        <p>durée : <span style="color:#ffa409" class="bolder-text">{{ simulate.duree }} {{ simulate.jours_mois }}</span></p>
                        <p>Date d'éffet : <span style="color:#ffa409" class="bolder-text">{{ DateFormater(simulate.dateEffet)  }}</span></p>
                        <p>Date d'écheance : <span style="color:#ffa409" class="bolder-text">{{ DateFormater(simulate.dateEcheance) }}</span></p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-center">
                        <p>Prime Nette : <span style="color:#ffa409" class="bolder-text">{{ simulate.prime_nette }}</span> </p>
                        <p>Accessoires : <span style="color:#ffa409" class="bolder-text">{{ simulate.accessoires }}</span></p>
                        <p>TAXE TVA : <span style="color:#ffa409" class="bolder-text" >{{ simulate.taxe }}</span></p>
                        <p>Prime TTC : <span style="color:#ffa409" class="bolder-text">{{ simulate.prime_ttc }}</span></p>
                    </div>
                </el-col>
            </el-row>

            <el-button @click="customer_phone_popup = true" type="primary" round>Payer</el-button>
        </div>

        <!-- <div class="item-box card-shadow--medium" style="margin-top:15px"  >
            <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
            
        </div> -->
        <el-dialog title="Numéro de téléphone du client" :visible.sync="customer_phone_popup">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label> Numéro de téléphone du client </label>
                            <el-input placeholder="téléphone du client" v-model="simulate.customer_phone" required></el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button  v-loading.fullscreen.lock="modalgenerationloading" @click="createPreContratAuto()" type="primary" round>Continuer</el-button>
                </div>
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>


//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import MicroAuto from "../../../models/micro/MicroAuto"

const validatePhone = phone => {
    if (!phone.length) {
        return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    } else {
        return { valid: true, error: null };
    }

};

const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "L'adresse e-mail est obligatoire!" };
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
        return { valid: true, error: null };
    }
};

export default {
    name: "devis_auto",
    data() {
        return {
            Micro_auto: new MicroAuto(),
            customer_phone_popup: false,
            simulate: {
                createur: null,
                courtier: null,
                duree: 0,
                jours_mois: "",
                pack: null,
                accessoires: "",
                prime_nette: null,
                taxe: null,
                prime_ttc: null,
                garanties: null,
                dateEffet: null,
                dateEcheance: null,
                dateSouscription: null,
                customer_phone: null,
                plateforme: 'Dash'
            },
            modalgenerationloading: false,
            isMobile: false,
        
            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },

        }
        
    },
    mounted() {

        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {

        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },

    methods: {

        clearDuree(){
            this.simulate.duree = null  
        },
        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format("DD-MM-YYYY");
            }
        },
        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },

        simulateAuto: function () {
            if (this.simulate.pack && this.simulate.duree) {
                // if (this.simulate.pack.namePack == "Pack_1") {
                //     this.simulate.jours_mois = "jours"
                // }
                this.simulate.pack = this.simulate.pack.namePack
                Api().post('micro/simulation/auto', this.simulate)
                    .then(resultat => {
                        this.simulate.prime_nette = resultat.data.devis.prime_nette
                        this.simulate.prime_ttc = resultat.data.devis.prime_ttc
                        this.simulate.accessoires = resultat.data.devis.accessoires
                        this.simulate.taxe = resultat.data.devis.taxe
                        this.simulate.fga = resultat.data.devis.fga
                        this.simulate.garanties = resultat.data.devis.garanties
                        this.simulate.pack = this.simulate.pack == this.Micro_auto.pack1.namePack ? this.Micro_auto.pack1 : this.Micro_auto.pack2
                    }).catch(err => console.log(err))
            }
        },

        createPreContratAuto() {
            if (this.simulate.customer_phone != "" && this.simulate.customer_phone != null) {
                this.modalgenerationloading = true
                if (this.$store.state.user.role == "Agent") {
                    this.simulate.courtier = this.$store.state.user.societe;
                }
                this.simulate.createur = this.$store.state.user._id
                this.simulate.pack =  this.simulate.pack.namePack
                Api().post('/micro/contrat/auto', this.simulate)
                .then(result => {
                        this.$store.dispatch('setDevisPayement', result.data)
                        this.customer_phone_popup = false
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                            this.$message.success('Contrat créer avec succés !', this.AlertMessage),
                            this.simulate = {
                                createur: null,
                                courtier: null,
                                duree: 0,
                                pack: null,
                                accessoires: "",
                                prime_nette: null,
                                taxe: null,
                                prime_ttc: null,
                                garanties: null,
                                dateEffet: null,
                                dateEcheance: null,
                                dateSouscription: null,
                                customer_phone: null,
                                plateforme: 'Dash'
                            }
                        ), 4000);
                    })
                    .catch(err => {
                        this.modalgenerationloading = false
                        console.log(err.message)
                        this.$message.error('Erreur := '+err.message, this.AlertMessage)
                    })
                    
            } else {
                this.$message.error('Le numéro de téléphone du client est obligatoire !', this.AlertMessage)
            }
        },

        getListePays: function () {
            Api().get('/pays/all')
                .then(response => {
                    this.listpays = response.data
                })
                .catch(err => {
                    err
                })
        },

        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },


        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        dateDiff: function () {

            if (this.simulate.pack == this.Micro_auto.pack1) {
                if (this.simulate.dateEffet) {
                    let inter = dayjs(this.simulate.dateEffet).add(this.simulate.duree, "day").format("YYYY-MM-DD");
                    this.simulate.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
                }
            } else if (this.simulate.pack == this.Micro_auto.pack2 && this.simulate.jours_mois == this.Micro_auto.periodeType.day) {
                if (this.simulate.dateEffet) {
                    let inter = dayjs(this.simulate.dateEffet).add(this.simulate.duree, "day").format("YYYY-MM-DD");
                    this.simulate.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
                }
            } else if (this.simulate.pack == this.Micro_auto.pack2 && this.simulate.jours_mois == this.Micro_auto.periodeType.month) {
                if (this.simulate.dateEffet) {
                    let inter = dayjs(this.simulate.dateEffet).add(this.simulate.duree, "month").format("YYYY-MM-DD");
                    this.simulate.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
                }
            }
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1

        //Validation des ellements du Stepper 2
        testVar: function (val) {
            if (val != null) {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },



        __resizeHanlder: _.throttle(function (e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),


    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.bolder-text {
    font-weight: bold;
}

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }

                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }

                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }

                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
