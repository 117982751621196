<template>
    <vue-scroll class="page-devis-voyage">

        <div class="page-header">
            <h1>Simulation devis <span style="color:#ffa409">micro Assur'Hospi</span>
                <theme-picker style="float:right"></theme-picker>
            </h1>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20">
            <div style="margin-top:15px">
                <el-row :gutter="15" type="flex" class="row-bg">
                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">

                            <el-col>
                                <label>Formule</label>
                           
                                <el-select style="width:100%" v-model="simulate.pack"
                                    placeholder="Selectionner une formule">
                                    <el-option value="Selectionner une formule" selected :disabled="true"></el-option>
                                    <el-option v-for="(items, idx) in listFormules" :key="idx" :label="items.namePack"
                                        :value="items.namePack">
                                        {{ items.capital.toLocaleString() + " CFA" }}
                                    </el-option>
                                </el-select>
                            </el-col>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <el-col>
                                <label>Durée en mois</label>
                          
                                <el-select v-model="simulate.duree" placeholder="Selectionner"
                                    style="width: 100%!important;">
                                    <el-option value="Nombre de mois" selected :disabled="true"></el-option>
                                    <el-option v-for="items in month" :key="items" :label="items" :value="items">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="24" style="margin-top:20px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <el-col>
                                <label>Date d'effet</label>
                           
                                <el-date-picker @change="dateDiff" style="width: 100%!important;"
                                    v-model="simulate.dateEffet" type="date" format="yyyy/MM/dd"
                                    placeholder="Choississez une date" :picker-options="dateAfterToday">
                                </el-date-picker>
                            </el-col>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="grid-content bg-purple" style="margin-bottom: 20px;margin-left: 20px;">
                            <el-col>
                                <label>Date d"échéance</label>
                                <el-input style="width: 100%!important;" placeholder="Date d'échéance" v-model="simulate.dateEcheance" readonly>
                                </el-input>
                            </el-col>
                        </div>

                    </el-col>
                </el-row>
                <el-col :span="24" style="margin-top:30px">

                    <el-descriptions style="font-size:12px" v-if="simulate.pack">
                        <hr />
                        <el-descriptions-item label="Garanties inclus ">
                            <span style="color:#ffa409"> {{ garanties }} </span>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>

            </div>

            <div style="margin-top:15px">
                <el-col>
                    <el-button @click="simulateVie()" type="primary" round>Simuler</el-button>
                </el-col>
            </div>
        </div>

        <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20"
            v-if="simulate.prime_ttc != null">
            <el-row>
                <el-col :span="12">
                    <div class="text-center">
                        <p>Pack : <span style="color:#ffa409" class="bolder-text">{{ simulate.pack }}</span> </p>
                        <p>durée : <span style="color:#ffa409" class="bolder-text">{{ simulate.duree }} mois</span></p>
                        <p>Date d'éffet : <span style="color:#ffa409" class="bolder-text">{{
                            DateFormater(simulate.dateEffet) }}</span></p>
                        <p>Date d'écheance : <span style="color:#ffa409" class="bolder-text">{{
                            DateFormater(simulate.dateEcheance) }}</span></p>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-center">
                        <p>Capital décés : <span style="color:#ffa409" class="bolder-text">{{ simulate.capital_deces }}</span>
                        </p>
                        <p>Capital hospitalisation : <span style="color:#ffa409" class="bolder-text">{{ simulate.capital_hospitalisation }}</span>
                        </p>
                        <p>Indemnite par jour : <span style="color:#ffa409" class="bolder-text">{{ simulate.indemnite }}</span></p>
                        <p>Prime TTC : <span style="color:#ffa409" class="bolder-text">{{ simulate.prime_ttc }}</span></p>
                    </div>
                </el-col>
            </el-row>

            <el-button @click="customer_phone_popup = true" type="primary" round>Payer</el-button>
        </div>

        <!-- <div class="item-box card-shadow--medium" style="margin-top:15px"  >
            <h3 class="text-center">PÉRIODE DE COUVERTURE</h3>
            
        </div> -->
        <el-dialog title="Numéro de téléphone du client" :visible.sync="customer_phone_popup">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label> Numéro de téléphone du client </label>
                            <el-input placeholder="téléphone du client" v-model="simulate.customer_phone" required></el-input>
                        </div>
                    </el-col>
                </el-row>

                <div style="margin-top:15px">
                    <el-button v-loading.fullscreen.lock="modalgenerationloading" @click="createPreContratVie()"
                        type="primary" round>Continuer</el-button>
                </div>
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>


//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'

const validatePhone = phone => {
    if (!phone.length) {
        return { valid: false, error: 'Le numéro de téléphone est obligatoire.' };
    } else {
        return { valid: true, error: null };
    }

};

const validateEmail = email => {
    if (!email.length) {
        return { valid: false, error: "L'adresse e-mail est obligatoire!" };
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        return { valid: false, error: "Veuillez entrer un email valide!" };
    } else {
        return { valid: true, error: null };
    }
};

export default {
    name: "devis_assurhospi_tmp",
    data() {
        return {
            customer_phone_popup: false,
            month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            simulate: {
                duree: null,
                pack: null,
                capital_deces: "",
                indemnite: null,
                capital_hospitalisation: null,
                prime_ttc: null,
                garanties: null,
                dateEffet: null,
                dateEcheance: null,
                dateSouscription: null,
                customer_phone: null,
                plateforme: 'Dash'
            },
            quote_simulate: null,
            modalgenerationloading: false,
            isMobile: false,

            dateAfterToday: {
                disabledDate(time) {
                    return time.getTime() < (dayjs(new Date()).add(-1, 'days')).toDate() //dayjs.datediff(Date.now(), );
                }
            },
            garanties: ['HOSPITALISATION', 'DECES ET INVALIDITE TOTALE ET DEFINITIVE'],
            listFormules: [
                {
                    namePack: "Pack_1",
                    capital: 100000,
                },
                {
                    namePack: "Pack_2",
                    capital: 500000,
                },
                {
                    namePack: "Pack_3",
                    capital: 1000000,
                },
            ]

        }

    },
    mounted() {

        if (store.state.isUserLoggedIn && store.state.user.role !== 'User') {

        } else {
            this.$router.go(this.$router.push('/login'))
        }
        window.addEventListener("resize", this.__resizeHanlder)
    },

    methods: {

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format("DD-MM-YYYY");
            }
        },
        init() {
            if (window.innerWidth <= 768) this.isMobile = true
        },

        simulateVie: function () {

            if (this.simulate.pack && this.simulate.duree) {

                Api().post('micro/simulation/vie', this.simulate)
                    .then(resultat => {
                        console.log("resultat.data =================")
                        console.log(resultat.data)
                        this.simulate.prime_ttc = resultat.data.devis.prime_ttc
                        this.simulate.capital_deces = resultat.data.devis.capital_deces
                        this.simulate.indemnite = resultat.data.devis.indemnite
                        this.simulate.capital_hospitalisation = resultat.data.devis.capital_hospitalisation
                        this.simulate.garanties = resultat.data.devis.garanties
                    }).catch(err => console.log(err))
            }
        },

        createPreContratVie() {
            if (this.simulate.customer_phone != "" && this.simulate.customer_phone != null) {
                this.modalgenerationloading = true
    
                Api().post('/micro/contrat/vie', this.simulate)
                    .then(result => {
                        this.$store.dispatch('setDevisPayement', result.data)
                        this.customer_phone_popup = false
                        setTimeout(() => (
                            this.modalgenerationloading = false,
                            this.$message.success('Contrat créer avec succés !', this.AlertMessage),
                            this.simulate = {
                                duree: 0,
                                pack: null,
                                capital_hospitalisation: "",
                                capital_deces: null,
                                indemnite: null,
                                prime_ttc: null,
                                garanties: null,
                                dateEffet: null,
                                dateEcheance: null,
                                dateSouscription: null,
                                customer_phone: null,
                                plateforme: 'Dash'
                            }
                        ), 4000);
                    })
                    .catch(err => {
                        this.modalgenerationloading = false
                        console.log(err.message)
                        this.$message.error('Erreur := ' + err.message, this.AlertMessage)
                    })

            } else {
                this.$message.error('Le numéro de téléphone du client est obligatoire !', this.AlertMessage)
            }
        },

        getListePays: function () {
            Api().get('/pays/all')
                .then(response => {
                    this.listpays = response.data
                })
                .catch(err => {
                    err
                })
        },

        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },


        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephone_port,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephone_port,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },
        dateDiff: function () {
console.log(this.simulate)
            if (this.simulate.pack && this.simulate.duree) {
                if (this.simulate.dateEffet) {
                    let inter = dayjs(this.simulate.dateEffet).add(this.simulate.duree, "month").format("YYYY-MM-DD");
                    this.simulate.dateEcheance = dayjs(inter).add(-1, "day").format("YYYY-MM-DD");
                }
            } else {
                this.$message.error('Selection la formule d\'abord !', this.AlertMessage)

            }
        },

        /*================================================================================================================================*/
        /*================================================================================================================================*/
        /*==========CETTE PARTIE EST SPECIALEMENT POUR UNE SERIE DE FONCTION PERMETTANT DE VALIDER LES MODELS POUR CHAQUE STEPPER=========*/
        //Validation des elements du Stepper 1

        //Validation des ellements du Stepper 2
        testVar: function (val) {
            if (val != null) {
                return Number(val.replace(/[^0-9.-]+/g, ""));
            } else {
                return "0";
            }
        },



        __resizeHanlder: _.throttle(function (e) {
            if (this.chart) {
                this.chart.resize()
            }
            if (this.pie) {
                this.pie.resize()
            }
        }, 700),


    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.bolder-text {
    font-weight: bold;
}

.page-ecommerce-dashboard {
    .widget {
        .icon-box {
            font-size: 30px;
        }
    }

    .table-box {
        .item-box {
            &.item-product {
                .product-image {
                    width: 50px;
                    margin-right: 15px;
                    float: left;

                    img {
                        width: 100%;
                    }
                }
            }

            &.item-status {
                padding: 5px 10px;

                &.status- {
                    &Complete {
                        background: rgba(44, 196, 120, 0.25);
                    }

                    &Pending {
                        background: rgba(247, 186, 42, 0.25);
                    }

                    &Returned {
                        background: rgba(243, 24, 71, 0.25);
                    }

                    &Paid {
                        background: rgba(45, 109, 211, 0.25);
                    }
                }
            }
        }
    }
}
</style>
