<template>
    <el-menu
        :default-active.sync="activeLink"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{ 'nav-collapsed': isCollapse }"
      >
       <!-- Dashboard -->
       <el-menu-item index="/home">
          <i class="mdi mdi-gauge"></i><span slot="title">Dashboards</span>
        </el-menu-item>
        <div class="el-menu-item-group__title" style="padding-top: 4px"><span>Menu Partenaire</span></div>
        <el-submenu :index="item.theme" popper-class="main-navigation-submenu" v-for="(item, index) in partenaireTierMenu" :key="index">
                <template slot="title">
                    <i class="mdi mdi-menu-down"></i>
                    <span>{{item.theme}}</span>
                </template>
                <el-menu-item :index="it.urlpath" v-for="(it, idx) in item.config" :key="idx">
                    <span slot="title">{{it.libelle}}</span>
                </el-menu-item>
        </el-submenu>

        <el-divider></el-divider>
        <div class="el-menu-item-group__title" style="padding-top: 4px"><span>Menu Assuraf</span></div>    
        <el-submenu :index="item.theme" popper-class="main-navigation-submenu" v-for="(item, index) in default_menu" :key="index">
            <template slot="title">
                <i class="mdi mdi-menu-down"></i>
                <span>{{item.theme}}</span>
              </template>
            <el-menu-item :index="it.urlpath" v-for="(it, idx) in item.config" :key="idx">
                <span slot="title">{{it.libelle}}</span>
            </el-menu-item>
        </el-submenu>
        <div class="collapse-menu-btn" :class="{ collapsed: isCollapse }">
            <button @click="$emit('collapse-nav-toggle')" class="flex align-center">
                <span v-if="!isCollapse"> <i class="mdi mdi-unfold-less-vertical"></i> Collapse </span>
                <span v-if="isCollapse"> <i class="mdi mdi-unfold-more-vertical"></i></span>
            </button>
        </div>
    </el-menu>
</template>

<script>
import Api from "../services/Api";
import store from "../store";
import { detect } from "detect-browser";
const browser = detect();

export default {
    name: "Nav",
    props: ["mode", "isCollapse"],
    data() {
        return {
          DeliveryCounter:0,
            assureur: true,
            courtier: true,
            partenaire: true,
            user: false,
            config: true,
            nonvueCount: null,
            SinistrenonvueCount: null,
            usermenu:[],
            partenaireTierMenu : [],
            default_menu :[],
            level_1_menu:[
                "Partenaires Tiers",
                "Contrat Cloud",
                "Contrat Cloud Hors SN",
                "Devis Cloud",
                "Devis Cloud Hors SN",
            ],
            isIe: true,
            isEdge: true,
            activeLink: null
        }
    },
    methods: {
        in_array(tab_, value_){
            let checker =0
            for(let v of tab_)
            {
                if(value_==v){
                    checker = checker+1
                }
            }
            return checker
        },
       
        CountSinistreNonVue() {
            Api()
                .get("/sinistre/count/nonvue")
                .then((resultat) => {
                    this.SinistrenonvueCount = resultat.data.count;
                });
        },
        CountNonVue() {
            Api()
                .get("/assistances/count/nonvue")
                .then((resultat) => {
                    this.nonvueCount = resultat.data.count;
                });
        },
        getUserMenu()
        {
            Api().post('/menu/user/menu', {userid:this.$store.state.user._id})
                .then((result) => {
                    this.usermenu = result.data.menus
                        for (let index of this.usermenu) 
                        {
                            if(this.in_array(this.level_1_menu, index.theme)>=1){
                                this.partenaireTierMenu.push(index)
                            }else{
                                this.default_menu.push(index)
                            }
                        }
                }).catch((err) => {
                console.log(err)
            });
        },
        goto(index, indexPath) {
            if (index.charAt(0) === "/") {
                this.$router.push(index)
                this.$emit("push-page", { page: index })
            }
        },
        setLink(path) {
            this.activeLink = path
        },
      checkToken() 
      {
        Api().get("/user/check/token")
        .then((resultat) => {
            if (!resultat.data.ok) 
            {
              store.state.isUserLoggedIn = false;
              store.dispatch("setUser", {});
              store.dispatch("setToken", {});
              this.$router.push("/login")
            }
          })
          .catch((err) => {
            if(err.response.data.ok === false)
            {
              store.state.isUserLoggedIn = false;
              store.dispatch("setUser", {});
              store.dispatch("setToken", {});
              this.$router.push("/login")
            }
          });
      },
    },
    mounted() {
        if (this.$store.state.isUserLoggedIn) {
            // this.checkToken();
            this.CountNonVue();
            this.CountSinistreNonVue();
            this.getUserMenu()
        } else {
            this.$router.go(this.$router.push("/login"));
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

.el-menu {
  border: none;
}
.el-menu::before,
.el-menu::after {
  display: none;
}
.el-submenu,
.el-menu-item {
  .mdi {
    vertical-align: middle;
    margin-right: 5px;
    display: inline-block;
    width: 24px;
    text-align: center;
    font-size: 18px;
  }
}

.collapse-menu-btn {
  button {
    border-radius: 4px;
    border: none;
    color: $text-color-accent;
    background: lighten($text-color-accent, 30);
    font-weight: 600;
    padding: 4px 8px;
    margin-top: 20px;
    font-size: 12px;
    margin-left: 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;

    i {
      font-size: 14px;
      margin-right: 5px;
    }
  }

  &.collapsed {
    button {
      margin-left: 17px;

      i {
        margin-right: -2px;
      }
    }
  }
}
</style>

<style lang="scss">
@import "../assets/scss/_variables";

.main-navigation-menu {
  transition: width 0.5s;

  &:not(.el-menu--collapse) {
    .el-submenu__title,
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      background-color: transparent !important;
    }

    &:not(.el-menu--horizontal) {
      .el-menu-item,
      .el-submenu {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 0px;
          height: 1px;
          position: absolute;
          bottom: 10px;
          left: 30px;
          background: $text-color;
          z-index: 1;
          opacity: 0;
          transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
        }
        &:hover {
          &::before {
            width: 100px;
            opacity: 1;
            //left: 50px;
            transform: translate(20px, 0);
          }
        }

        &.is-active {
          &::before {
            background: $text-color-accent;
          }
        }
      }
    }

    .el-submenu.is-opened {
      //background: #edf1f6 !important;
      //background: rgba(223, 228, 234, 0.38) !important;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 2px;
        position: absolute;
        top: 40px;
        bottom: 10px;
        left: 31px;
        background: $text-color;
        z-index: 1;
      }

      &::before {
        display: none;
      }

      .el-menu-item,
      .el-submenu {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    .el-menu-item-group__title {
      padding: 15px 0 0px 20px;
      color: transparentize($text-color, 0.65);
    }
  }

  .el-submenu__title,
  .el-menu-item:not(.is-active) {
    color: $text-color;

    i {
      color: $text-color;
    }
  }

  &.el-menu--collapse {
    .el-menu-item-group__title {
      padding: 15px 0 0px 0px;
      width: 100%;
      text-align: center;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }

  &.el-menu--horizontal {
    white-space: nowrap;
    /*width: fit-content;
		width: max-content;*/
    overflow: hidden;
    display: table;

    & > * {
      float: inherit !important;
      display: inline-block;
    }
  }

  &.nav-collapsed {
    .el-menu-item,
    .el-submenu__title {
      & > span {
        display: none;
      }
    }
  }
}

.main-navigation-submenu {
  .el-menu {
    background: #fff !important;

    .el-menu-item:not(.is-active) {
      color: $text-color;
    }
    .el-menu-item:hover {
      background-color: transparentize($background-color, 0.3) !important;
    }
  }
}
</style>
