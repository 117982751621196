<template>
    <div class="page-table column scrollable only-y" :class="{ flex: !isMobile, overflow: isMobile }">

        <el-dialog width="70%!important" title="Liste des passagers" :visible.sync="modalInfoVoyageur"
            v-if="modalInfoVoyageur == true && !isMobile">
            <div class="grid-content bg-purple">
                <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-show="passportStep">
                    <h3>Choisir mes passeports ou nouveau passeport :</h3>
                    <el-row justify="center">
                        <el-col>
                            <div class="grid-content bg-purple">
                                <el-radio @change="myPassportValidator()" v-model="myPassports" size="medium"
                                    label="mes_passport">Mes
                                    passeports</el-radio>
                            </div>
                        </el-col>
                        <el-col>
                            <div class="grid-content bg-purple-light">
                                <el-radio @change="myPassportValidator()" v-model="myPassports" label="nouveau">Nouveau
                                    passeport
                                </el-radio>
                            </div>
                        </el-col>
                    </el-row>
                </div>

                <div class="card-base card-shadow--medium bg-white black-text ph-5 p-0 pb-20 mt-20" v-show="myPassportList">
                    <el-table :data="listPassports" class="padded-table" height="200" style="width: 100%" row-key="id">
                        <el-table-column prop="prenom_passager" label="Prénom" min-width="200"> </el-table-column>
                        <el-table-column prop="nom_passager" label="Nom" min-width="200"> </el-table-column>
                        <el-table-column prop="nationalite_passager" label="Nationalité" min-width="200"> </el-table-column>
                        <el-table-column prop="numero_ci_passager" label="Numéro passeport" min-width="200">
                        </el-table-column>
                        <el-table-column prop="dateNaissance" label="Date de naissance" min-width="200">
                            <template slot-scope="scope">
                                {{ DateFormater(scope.row.dateNaissance) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="date_delivrance" label="Date de délivrance" min-width="200">
                            <template slot-scope="scope">
                                {{ DateFormater(scope.row.date_delivrance) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="date_expiration" label="Date d'expiration" min-width="200">
                            <template slot-scope="scope">
                                {{ DateFormater(scope.row.date_expiration) }}
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="Actions" prop="action" min-width="120" v-if="!isMobile">
                            <template slot-scope="scope">
                                <el-button @click="preContratValidation(scope.row)" type="primary" icon="el-icon-right"
                                    circle title="Voir détails"></el-button>
                                <el-button @click="deletePassport(scope.row)" type="danger" icon="el-icon-delete" circle
                                    title="Désarchiver"></el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="Actions" prop="action" min-width="120" v-if="isMobile">
                            <template slot-scope="scope">
                                <el-button @click="preContratValidation(scope.row)" type="primary" icon="el-icon-right"
                                    circle title="Voir détails"></el-button>
                                <el-button @click="deletePassport(scope.row)" type="danger" icon="el-icon-delete" circle
                                    title="Désarchiver"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mb-5" style="margin-top: 15px">
                        <el-button round @click="backToPassportStep()">Retour</el-button>
                    </div>
                </div>

                <el-card class="box-card" v-show="newPassport">
                    <div>
                        <el-row :gutter="15">
                            <el-col :span="8">
                                <div>
                                    <el-row justify="center">
                                        <el-col>
                                            <div class="grid-content bg-purple">
                                                <el-radio v-model="genre" size="medium" label="femme">Madame</el-radio>
                                            </div>
                                        </el-col>
                                        <el-col>
                                            <div class="grid-content bg-purple-light">
                                                <el-radio v-model="genre" label="homme">Monsieur</el-radio>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <label>Nom</label>
                                    <el-input v-model="nom_passager" placeholder="Votre nom"
                                        class="input-with-select"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <label>Prénom</label>
                                    <el-input v-model="prenom_passager" placeholder="Votre prénom"
                                        class="input-with-select"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 15px" :gutter="15">
                            <!-- <el-col :span="8">
                                <div>
                                    <label>Numéro de téléphone</label>
                                    <el-input type="number" v-model="num_passager" placeholder="Votre numéro de téléphone"
                                        class="input-with-select"></el-input>
                                </div>
                            </el-col> -->
                            <el-col :span="8">
                                <div>
                                    <label>Date de naissance</label>
                                    <el-input type="date" v-model="datenaissance" placeholder="Votre date de naissance"
                                        class="input-with-select"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <label>Nationalité</label>
                                    <el-select style="width: 100%!important;" v-model="nationalite_passager"
                                        placeholder="Selectionner">
                                        <el-option v-for="items in listpays" :value="items.PAYS" :key="items.PAYS"
                                            :label="items.PAYS">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-top: 15px" :gutter="15">
                            <el-col :span="8">
                                <div>
                                    <label>Numéro passeport</label>
                                    <el-input v-model="numero_ci_passager" placeholder="Votre numéro de passeport"
                                        class="input-with-select"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <label>Date de délivrance</label>
                                    <el-date-picker style="width: 100%!important;" v-model="date_delivrance" type="date"
                                        placeholder="Choississez une date">
                                    </el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div>
                                    <label>Date d'expiration</label>
                                    <el-date-picker style="width: 100%!important;" v-model="date_expiration" type="date"
                                        placeholder="Choississez une date">
                                    </el-date-picker>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="mb-5" style="margin-top: 15px">
                        <el-button type="primary" round @click="Detail_info_passager()">Ajouter</el-button>
                        <el-button round @click="backToPassportStep()">Retour</el-button>
                    </div>

                    <div class="table-box card-base card-shadow--medium box grow" id="table-wrapper" v-loading="!ready"
                        style="margin-top:15px!important">
                        <el-table :data="listInPagePassager" style="width: 100%" height="150px" v-if="ready"
                            v-show="listcontrat.length > 0" @selection-change="handleSelectionChange">
                            <el-table-column label="Nom" min-width="150" fixed prop="nom_passager"></el-table-column>
                            <el-table-column label="Prénom" min-width="150" prop="prenom_passager"></el-table-column>
                            <el-table-column label="Date de naissance" min-width="150" prop="dateNaissance">
                                <template slot-scope="scope">
                                    {{ DateFormater(scope.row.dateNaissance) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Numéro passeport" min-width="150"
                                prop="numero_ci_passager"></el-table-column>
                            <el-table-column label="Nationalité" min-width="150"
                                prop="nationalite_passager"></el-table-column>
                            <el-table-column label="Date de délivrance" min-width="200" prop="date_delivrance">
                                <template slot-scope="scope">
                                    {{ DateFormater(scope.row.date_delivrance) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Date de d'expiration" min-width="200" prop="date_expiration"
                                fixed="right">
                                <template slot-scope="scope">
                                    {{ DateFormater(scope.row.date_expiration) }}
                                </template>
                            </el-table-column>
                        </el-table>

                        <el-pagination v-if="ready" :small="pagination.small" :current-page.sync="pagination.page"
                            :page-sizes="pagination.sizes" :page-size.sync="pagination.size" :layout="pagination.layout"
                            :total="totalPassager"></el-pagination>
                    </div>

                    <div class="mb-5" style="margin-top: 15px" v-show="continue_btn">
                        <el-button type="primary" round @click="ContratVoyage(detailsContrat)">Continuer</el-button>
                    </div>
                </el-card>
            </div>
        </el-dialog>

        <el-dialog width="70%" title="Créer un client" :visible.sync="AddUser" v-if="!isMobile">
            <el-form ref="form" label-width="120px" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Nom</label>
                            <el-input placeholder="Votre nom" v-model="userInfo.nom"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Prénom</label>
                            <el-input placeholder="Votre prenom" v-model="userInfo.prenom"></el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="12">
                        <div class="grid-content bg-purple">
                            <label>Numéro de téléphone</label>
                            <el-input placeholder="Numéro de téléphone" v-model="userInfo.telephone_port"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="grid-content bg-purple-light">
                            <label>Adresse e-mail</label>
                            <el-input placeholder="votre adresse e-mail" v-model="userInfo.email"></el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20" style="margin-top:15px">
                    <el-col :span="24">
                        <div class="grid-content bg-purple">
                            <label>Sexe</label>
                            <el-select style="width: 100%!important;" v-model="userInfo.sexe"
                                placeholder="Selectionner votre sexe">
                                <el-option label="Homme" value="homme"></el-option>
                                <el-option label="Femme" value="femme"></el-option>
                                <el-option label="Entreprise" value="entreprise"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
            <div style="margin-top:15px">
                <el-button type="primary" round @click="createuser()">Créer</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash"
import moment from "moment-timezone"

//debut importation assuraf
import dayjs from 'dayjs'
import Api from '../../../services/Api'
import store from '../../../store'
import helsper from '../../../services/helper'
import customPassword from "../../../services/password_generator"

export default {
    data() {
        return {
            listRoles: null,
            userRole: null,
            userInfo: {
                nom: "",
                prenom: "",
                sexe: "",
                telephone_port: "",
                email: "",
                password: "",
                confirmPass: "",
                role: "",
                plateforme: 'Dash',
                createdby: store.state.user._id,
                created: dayjs(new Date()).format('YYYY-MM-DD')
            },
            AddUser: false,
            ToastTabSucess: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'success',
                className: 'custom-success-class'
            },
            ToastTabError: {
                theme: 'toasted-primary',
                position: 'top-center',
                duration: 10000,
                fitToScreen: true,
                type: 'error'
            },
            nom_passager: "",
            prenom_passager: "",
            num_passager: "",
            nationalite_passager: "",
            numero_ci_passager: "",
            datenaissance: "",
            date_delivrance: "",
            date_expiration: "",
            genre: "",
            precontrat: null,
            passportStep: true,
            myPassportList: false,
            myPassports: null,
            newPassport: false,
            usersPasseport: [],
            listPassports: [],
            PassportDetails: [],
            tableau_info_passager_from_list: [],
            listPassager: [],
            tableau_info_passager: [],
            modalInfoVoyageur: false,
            headerContrat: [
                {
                    text: "Prénom",
                    sortable: false,
                    value: "prenom_passager"
                },
                {
                    text: "Nom",
                    sortable: false,
                    value: "nom_passager"
                },
                {
                    text: "Nationalité",
                    sortable: false,
                    value: "nationalite_passager"
                },
                {
                    text: "Num Passport",
                    sortable: false,
                    value: "numero_ci_passager"
                },
                {
                    text: "date Naissance",
                    sortable: false,
                    value: "dateNaissance"
                },
                {
                    text: "Date délivrance",
                    sortable: false,
                    value: "date_delivrance"
                },
                {
                    text: "Date expiration",
                    sortable: false,
                    value: "date_expiration"
                },
                {
                    text: "Action",
                    value: "action"
                },
            ],
            usersearch: null,
            listpays: null
        }
    },
    methods: {
        AlertMessage() {
            this.$message({
                showClose: false,
                duration: 5000
            });
        },
        errorNotif() {
            this.$notify({
                title: 'Error',
                message: 'Numéro obligatoire !',
                position: 'top-right',
                type: 'error'
            });
        },
        errorNotifCreateUser() {
            this.$notify({
                title: 'Error',
                message: 'Tout les Champs sont obligatoires !',
                position: 'top-right',
                type: 'error'
            });
        },
        successNotifCreateUser() {
            this.$notify({
                title: 'Success',
                message: 'Utilisateur crée avec succés !',
                type: 'success'
            });
        },
        HubspotSend(param) {
            let data = {
                "email": param.email,
                "firstname": param.prenom,
                "lastname": param.nom,
                "phone": param.telephoneclient,
                "inscrit": "Dash"
            };
            Api().post('/testimonial/hubspot', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        MailChimpInscriptionCompte(param) {
            let data = {
                "email": param.email,
                "FNAME": param.prenom,
                "LNAME": param.nom,
                "PHONE": param.telephoneclient,
            };
            Api().post('/testimonial/mailchimp', data)
                .then(resp => {
                    (resp.data)
                })
                .catch(e => {
                    (e)
                })
        },

        //Creation d'un user avant de faire son devis
        createuser: function () {
            this.generatePassword()
            if (this.userInfo.nom && this.userInfo.prenom && this.userInfo.email && this.userInfo.sexe && this.userInfo.telephone_port) {
                Api().post('user/register', this.userInfo)
                    .then(resultat => {
                        //this.HubspotSend(this.userInfo)
                        //this.MailChimpInscriptionCompte(this.userInfo)
                        if (resultat.status == 200) {
                            if (resultat.data.numero) {
                                this.$message.error(resultat.data.msg, this.AlertMessage)
                            } else {
                                this.prenom_passager = this.userInfo.prenom
                                this.nom_passager = this.userInfo.nom
                                this.genre = this.userInfo.sexe
                                this.usersearch = resultat.data.data
                                this.AddUser = false
                                this.modalInfoVoyageur = true
                                this.myPassports = 'nouveau'
                                this.newPassport = true
                                this.listInPagePassager = []
                                this.successNotifCreateUser()
                            }
                        } else {
                            this.$message.error('Ajout utilisateur échoué !!!', this.AlertMessage)
                        }
                    })
                    .catch(err => {
                        this.$message.error('Ajout utilisateur échoué !', this.AlertMessage)
                    })

            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }

        },

        generatePassword: function () {
            this.userInfo.password = customPassword.customPassword(this.nom, this.prenom)
            this.userInfo.confirmPass = this.userInfo.password
            this.userInfo.role = this.userRole
        },

        getUserRoles() {
            Api().get('/roles/all')
                .then(response => {
                    this.listRoles = response.data.AllRoles
                    for (let lr of response.data.AllRoles) {
                        if (lr.name == "User") {
                            this.userRole = lr._id
                        }
                    }
                }).catch(err => { console.log("failed getAllUserRoles"); })
        },

        DateFormater: function (TheDate) {
            if (TheDate) {
                return dayjs(TheDate).format('DD-MM-YYYY')
            }
        },

        getListePays: function () {
            Api().get('/pays/all')
                .then(response => {
                    this.listpays = response.data
                })
                .catch(err => {
                    err
                })
        },

        rechercherClient(item) {
            Api().get('/user/phone/' + item.customer_phone)
                .then(resultat => {
                    if (resultat.data.telephone_port === item.customer_phone) {
                        this.usersearch = resultat.data;
                        this.modalInfoVoyageur = true
                    } else {

                    }
                })
                .catch(e => {
                    if (e.response.data.msg == "User not found" && e.response.data.status == 404) {
                        this.userInfo.telephone_port = item.customer_phone
                        this.AddUser = true
                        // this.myPassports == 'nouveau'
                        // this.usersearch = resultat.data;
                        // this.modalInfoVoyageur = true
                    }
                })

        },

        myPassportValidator() {
            if (this.myPassports == 'nouveau') {
                this.newPassport = true
                this.passportStep = false
                this.listInPagePassager = []
            } else {
                this.passportStep = false
                this.myPassportList = true
                this.listPassports = this.usersPasseport
                this.myPassports = 'mes_passport'

                Api().post("mypassports/userpassport", { userid: this.usersearch._id })
                    .then(response => {
                        this.listPassports = response.data.mespasseports
                    });
            }
        },
        backToPassportStep() {
            this.myPassportList = false
            this.newPassport = false
            this.passportStep = true
            this.myPassports = null
        },

        deletePassport(item) {
            Api().post('/mypassports/delete', { passportid: item._id })
                .then(response => {
                    if (response.status == 200) {
                        this.$message.success('Passport supprimé avec succés !', this.AlertMessage)
                        Api().post("mypassports/userpassport", { userid: this.detailsContrat.userid._id })
                            .then(response => {
                                this.listPassports = response.data.mespasseports
                            });
                    }
                })
                .catch(err => { })

        },

        saveUserPassport() {
            const UserPassport = {
                userid: this.usersearch._id,
                prenom_passager: this.prenom_passager,
                nom_passager: this.nom_passager,
                nationalite_passager: this.nationalite_passager,
                numero_ci_passager: this.numero_ci_passager,
                dateNaissance: this.datenaissance,
                date_delivrance: this.date_delivrance,
                date_expiration: this.date_expiration,
                genre: this.genre
            }

            Api().post("mypassports/save", UserPassport)
                .then(response => {
                    this.ContratVoyage()
                    this.$message.success('Passport enregistré avec succés !', this.AlertMessage)
                    this.nom_passager = ""
                    this.prenom_passager = ""
                    this.nationalite_passager = ""
                    this.numero_ci_passager = ""
                    this.datenaissance = ""
                    this.date_delivrance = ""
                    this.date_expiration = ""
                    this.genre = ""
                });
        },


        // FOnction permettant de recolter les info sur les passager du voyage (Si souscription a un contrat d'assurance voyage)
        Detail_info_passager() {
            if (this.nom_passager && this.prenom_passager && this.nationalite_passager) {
                if (this.numero_ci_passager && this.date_delivrance && this.date_expiration && this.datenaissance && this.genre) {
                    let Un_passager =
                    {
                        nom_passager: this.nom_passager,
                        prenom_passager: this.prenom_passager,
                        nationalite_passager: this.nationalite_passager,
                        numero_ci_passager: this.numero_ci_passager,
                        dateNaissance: this.datenaissance,
                        date_delivrance: this.date_delivrance,
                        date_expiration: this.date_expiration,
                        genre: this.genre
                    }
                    this.tableau_info_passager.push(Un_passager)
                    this.saveUserPassport()

                } else {
                    this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
                }
            } else {
                this.$message.error('Tous les champs sont obligatoires !', this.AlertMessage)
            }
        },

        dataBuilder() {
            const data = {
                userid: this.usersearch._id,
                createur: store.state.user._id,
                brancheid: this.precontrat.brancheid._id,
                partenaireid: this.precontrat.partenaireid._id,
                dateEffet: this.precontrat.dateEffet,
                dateEcheance: this.precontrat.dateEcheance,
                dateSouscription: this.precontrat.dateSouscription,
                taxe: this.precontrat.taxe,
                prime_nette: this.precontrat.prime_nette,
                prime_ttc: this.precontrat.prime_ttc,
                accessoires: this.precontrat.accessoires,
                details:this.tableau_info_passager,
                mode_remboursement_voyage: "remboursable",
                plateforme: "Dash",
            }

            return data
        },

        ContratVoyage() 
        {
            const param = this.dataBuilder()
            let objetContrat = {
                precontract_id :this.precontrat._id,
                userid: param.userid,
                createur: store.state.user._id,
                brancheid: param.brancheid,
                partenaireid: param.partenaireid,
                dateEffet: param.dateEffet,
                dateEcheance: param.dateEcheance,
                duree : dayjs(param.dateEcheance).diff(param.dateEffet, 'day'),
                dateSouscription: param.dateSouscription,
                taxe: param.taxe,
                prime_nette: param.prime_nette,
                prime_ttc: param.prime_ttc,
                accessoires: param.accessoires,
                details: this.tableau_info_passager,
                mode_remboursement_voyage: "remboursable",
                plateforme: "Dash",
            }

            // console.log(objetContrat)

            Api().post('/micro/contrat/voyage/validation', objetContrat)
                .then(response => {
                    localStorage.removeItem('preTocontrat')
                    switch (response.data.message) {
                        case 'Contrat cree mais non envoye par email':
                            this.contratToStore = response.data.contrat
                            this.contratToStore.assureur = response.data.assureur
                            this.contratToStore.branche = response.data.Thebranche
                            this.$store.dispatch('setNewcontrat', this.contratToStore)
                            this.$router.go(this.$router.push('/contrat/ok'))
                            break;

                        case 'Contrat cree et envoye par email':
                            this.contratToStore = response.data.contrat
                            this.contratToStore.assureur = response.data.assureur
                            this.contratToStore.branche = response.data.Thebranche
                            this.$store.dispatch('setNewcontrat', this.contratToStore)
                            this.$router.go(this.$router.push('/contrat/ok'))
                            break;

                        case 'YET':
                            this.$message.error('Il existe déjà un contrat pour ce devis !', this.AlertMessage)
                            break;

                    }
                }).catch(e => {
                    console.log(e)
                })
        },

        preContratValidation(item)
        {
            this.tableau_info_passager.push(item)
            this.ContratVoyage()
        }
    },
    mounted() 
    {
        this.precontrat = JSON.parse(localStorage.getItem("preTocontrat"))
        if (this.precontrat != null) 
        {
            this.rechercherClient(this.precontrat)
            this.getListePays()
            this.getUserRoles()
        }
    },
}
</script>

<style></style>