import axios from 'axios'
import store from '../store'
import router from '../router'
export default () => {
  const axiosConf = axios.create({
    // baseURL: `http://localhost:5004/`,
    baseURL: `https://assurapi.assuraf.com/apicloud/`,    
    headers: {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'user': store.state.user
    }
  })
  axiosConf.defaults.headers['apikey'] = store.state.apikey
  axiosConf.defaults.headers['paraphrase'] = store.state.paraphrase
  // axiosConf.defaults.headers['apikey'] = "ede27a2aa4a3154852b19dd74d23783e50758c35236e933fd1645104254dc79066d7558291bfd3c3bbe05a58c081a3b622cd11c6f783f1522ac3aa810e0ff0c572ad2d5cdb22a08cbd37d66d79b99f8aaf8f8c0c2aabc1e6ade36d190c05e2f0a0dfd9217aaa77d253c2a41f10fc74e3f2ec22e821482e034832630796141554cfcbcb07870c6f3ee4d039ccc3ea0c288241bb4486761cea0239bb9ff2b1b508681da129c669c8eacde2688b9225d22c1d1277101b40386b9fff45a27965a7e761f1a0572826cd8c0f14edc203df1f18e0e305c9305c51ba202a5d797761f021b626b13352867f2d30067f20fe4f1b7540c5b2bab3917eaa941f3b1e9b162364"
  // axiosConf.defaults.headers['paraphrase'] = "ASSURAFSNu9CoMaq24Y"
  axiosConf.defaults.headers.common['Authorization'] = "Bearer " + store.state.token
  axiosConf.defaults.headers.post['Content-Type'] = 'Application/json'
  axiosConf.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  return axiosConf
}